<template>
  <div class="row">
      <div class="col-12">
          <div class="bannerBox">
              <div class="imageBox">
                  <img :src="`${baseurl}${banners.bannerURL}`"/>
               </div>
               <div class="shareBox">
                   <p class="proCatName">
                       <template v-if="banners.productID!=''">
                           {{banners.prName}}
                       </template>
                        <template v-if="banners.catId!=''">
                            {{banners.catName}}
                        </template>   
                       
                       </p>
                   <a href="javascript:void(0)" @click="shareInfb()"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                   <span class="embedddLink" > <a href="javascript:void(0)" @click="openEmbeddedBlockBox()"><img src="/assets/img/embeded.png" /></a></span>
                

                       <div class="embeddedScript" v-if="openEmbededBlock==true">
                           {{embeddedScript}}
                           <div class="copyScript" @click="copytoClipboard()"><i class="fa fa-copy"></i></div>
                       </div>
                </div>
          </div>
          
         
      </div>
     

  </div>
</template>

<script>
export default {
    name:"AffiliatesCard",
    props:[
        'banners',
        'baseurl',
        'siteUrl'
    ],
    data(){
        return{
            embeddedScript:'',
            cust_id:'',
            openEmbededBlock:false
        }
    },
    mounted(){
        this.generateEmbeddedScript();

    },
  
    methods:{
        openEmbeddedBlockBox(){
           
            this.openEmbededBlock =!this.openEmbededBlock;
        },
        generateEmbeddedScript(){
            this.embeddedScript=`
                <a href="${this.siteUrl}track?bid=${this.banners.bannerId}&cust_id=${localStorage.getItem("custId")}">
                    <img src='${this.baseurl}${this.banners.bannerURL}' style='width:${this.banners.bannerWidth}px;height:${this.banners.bannerHeight}px'/>
                </a>
            `;
           
        },
        shareInfb(){
        let u=this.baseurl+this.banners.bannerURL;
        let rdirectUrl =this.siteUrl+'track/?bid='+this.banners.bannerId+'&cust_id='+localStorage.getItem("custId");
     
        let t=this.banners.productID!=''?this.baseurl+this.banners.Product:this.banners.Category;
            window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(u)+'&t='+encodeURIComponent(t)+'&href='+encodeURIComponent(rdirectUrl)+'"','sharer','toolbar=0,status=0,width='+this.banners.bannerWidth+',height='+this.banners.bannerHeight+'');return false;
        },
        copytoClipboard(){
            navigator.clipboard.writeText(this.embeddedScript);
            this.$toast.success(`Script copied to clipboard `)
        }
    }
}
</script>

<style scoped>
    .imageBox{
         width: 165px;
        background-color: #fff;
        margin: 10px;
        border-radius: 40px
      
    }
    .imageBox img{
        border-radius: 5px;
    }
    .bannerBox{ 
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 4px 6px 8px 2px rgb(177 177 177 / 25%), -5px -5px 30px 7px rgb(191 181 181 / 22%);
        transition: .4s;
    }

    .shareBox{
        padding-top: 18px;    
        padding-left: 18px;
    }
    .shareBox .fa-facebook-square{
        font-size: 40px;
        color: #2d2d9b;
         cursor: pointer;
    }
    .embedddLink img{
       width: 50px;
        margin-top: -16px;
        margin-left: 20px;
         cursor: pointer;

    }
    .proCatName{
       font-size: 1.8rem;
    }
    .embeddedScript{
        width: 454px;
        position: relative;
        overflow-x: scroll;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 10px;
    }

    .copyScript .fa-copy{
        float: right;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 10px;
        cursor: pointer;
    }
    .copyScript .fa-copy:hover{
        background-color: #42c742;
        color: #fff;
    }
      @media only screen and (max-width: 1200px) {
          .embeddedScript{
               width: 250px!important;
          }
      }
    @media only screen and (max-width: 600px) {
        .bannerBox{
            display: block!important;
        }
        .imageBox{
            width:100%!important;
            margin: 0px!important;
            background-color: #fff;
            /* min-height: 172px; */
        }
        .shareBox{
            clear: both;
        }
        .shareBox .fa-facebook-square{
        font-size: 40px;
        color: #2d2d9b;
        }
        .embedddLink img{
            width: 50px;
            margin-top: -16px;
            margin-left: 20px;

        }
         .proCatName{
         font-size: 1.2rem;
        }
        .embeddedScript{
            width: 100%!important;
            margin-bottom:10px
        }
        .shareBox{
            padding-left: 0px!important;
        }
    }
</style>