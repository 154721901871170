<template>
   <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain"  @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard"  class="hidden-menu" style="display: none;">
            <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <template v-if="affiliatesBanners!=''">
              <template v-for="(affiliatesBanner,index) in affiliatesBanners" :key="index">
                
                  <AffiliatesCard :banners="affiliatesBanner" :siteUrl="siteUrl" :baseurl="baseUrl"></AffiliatesCard>
              </template>
          </template>
          <template v-if="ajaxLoad!=true && affiliatesBanners.lenght <1">
             <div class="text-center">
                <img  src="/assets/img/no-data-found.png" />
             </div>
          </template>
           
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import AffiliatesCard from "@/components/affiliatesprogram/AffiliatesCard";
import axios from "axios";
import Config from "@/config";
import helper from "@/helper/helper";
export default {
      name:'AffiliationProgram',
      components: {
        Breadcrumb,
        SideBar,  
        AffiliatesCard
    },
       props:[
        'translationloaded',
        'translate'
    ],
    data(){
        return {
             urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/affiliation-program",
          name: "affilationProgram",
        },
      ],
      affiliatesBanners:[],
      baseUrl:'',
      siteUrl:'',
      ajaxLoad:true
        }
    },
    mounted(){
        this.getBannerList();
        this.baseUrl =Config.WEB_ADMIN_URL;
        this.siteUrl =Config.SITE_URL;
        helper.backtoTop();
    },
    methods:{
       toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
        getBannerList(){
            let $this=this;
            axios.get(`${Config.BASE_URL}AllAffiliatedBanners`).then(function(response){
                
                $this.affiliatesBanners =response.data.Data;
                $this.ajaxLoad=false;
            }).catch(()=>{
               $this.ajaxLoad=false;
            });
        }
    }

}
</script>

<style>

</style>